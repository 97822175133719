import pages from "../pages/index";

const routes = [
  //Micro App
  {
    resource: "microapp",
    path: "application",
    element: pages.MicroApp,
    children: [
      {
        path: "new",
        element: pages.CreateApp,
      },
      {
        path: ":id",
        element: pages.EditApp,
      },
      {
        path: "edit-user-profile",
        element: pages.EditIndividualProfile,
      },
      {
        path: "edit-company-profile",
        element: pages.EditCompanyProfile,
      },
      {
        path: "change-password-user",
        element: pages.ChangePassword,
      },
    ],
  },
    //Merchant
    {
      resource: "merchant",
      path: "merchant",
      element: pages.Merchant,
      children: [
        {
          path: "new",
          element: pages.CreateMerchant,
        },
        {
          path: ":id",
          element: pages.EditMerchant,
        },
      ],
    },
  //Resource
  {
    resource: "resource",
    path: "resource",
    element: pages.Resource,
    children: [
      // {
      //   path: "change-password/:id",
      //   element: pages.ChangePassword,
      // },
      // {
      //   path: "new",
      //   element: pages.CreateApp,
      // },
      // {
      //   path: ":id",
      //   element: pages.AccountDetails,
      // },
      // {
      //   path: "change-password-user",
      //   element: pages.ChangePass,
      // },
    ],
  },
  //   User Management
  {
    resource: "user",
    path: "user",
    element: pages.Users,
    children: [
      {
        path: "new",
        element: pages.CreateUser,
      },
      
    ],
  },
];

export default routes;
