export default {
  button: {
    primary: {
      base: "text-white bg-green-500 border border-transparent",
      active:
        "active:bg-green-500 hover:bg-green-500 focus:ring focus:ring-nicegreen",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-nicegreen border-nicegreen border dark:text-nicegreen focus:outline-none",
      active:
        "active:bg-transparent hover:border-nicegreen focus:border-nicegreen active:text-nicegreen focus:ring focus:ring-nicegreen",
      disabled: "opacity-50 cursor-not-allowed bg-green-500",
    },
  },
  // Input
  input: {
    active:
      "focus:border-nicegreen border-nicegreen dark:nicegreen focus:ring focus:ring-nicegreen dark:focus:border-nicegreen dark:focus:ring-nicegreen dark:bg-gray-700",
    checkbox:
      "text-nicegreen form-checkbox focus:border-nicegreen focus:outline-none focus:ring focus:ring-nicegreen focus:ring-offset-0 rounded dark:focus:ring-nicegreen ",
    radio:
      "text-nicegreen form-radio dark:border-nicegreen focus:border-nicegreen focus:outline-none focus:ring focus:ring-nicegreen focus:ring-offset-0",
  },
};
