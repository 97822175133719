import { lazy } from "react";

const pages = {
  Page404: lazy(() => import("./404")),
  GetStarted: lazy(() => import("./GetStarted")),
  //Register
  Register: lazy(() => import("./Register")),
  // Login
  Login: lazy(() => import("./Login")),
  //Users
  Users: lazy(() => import("./Users/index.js")),
  CreateUser: lazy(() => import("./Users/CreateNewUser")),
  //Profile
  ChangePassword: lazy(() => import("./Profile/ChangePass")),
  EditIndividualProfile: lazy(() => import("./Profile/EditIndividualProfile")),
  EditCompanyProfile: lazy(() => import("./Profile/EditCompanyProfile.js")),
  //Micro App
  MicroApp: lazy(() => import("./MicroApp/index")),
  CreateApp: lazy(() => import("./MicroApp/CreateNewApp")),
  // AppDetails: lazy(() => import("./MicroApp/AppDetails")),
  EditApp: lazy(() => import("./MicroApp/EditApp")),
  //Micro App
  Merchant: lazy(() => import("./Merchant/index.js")),
  CreateMerchant: lazy(() => import("./Merchant/CreateMerchant.js")),
  // AppDetails: lazy(() => import("./MicroApp/AppDetails")),
  EditMerchant: lazy(() => import("./Merchant/EditMerchant.js")),
  //Resource
  Resource: lazy(() => import("./Resources/index")),
  //Password
  ForgotPassword: lazy(() => import("./ForgotPassword")),
  ResetPassword: lazy(() => import("./ResetPassword")),
};

export default pages;
