import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ resource, authenticatedRedirect, children }) => {
  const { authenticated, user } = useContext(AuthContext);
  // console.log(user)
  if (authenticated === undefined) return null;

  if (authenticated) {
    const permissions = user?.Type?.permissions;
    // const permissions = ["user"];
    if (permissions?.includes(resource)) return children;
    else return <Navigate to={`/app/${authenticatedRedirect}`} replace />;
  }

  return <Navigate to="/get-started" replace />;
};

export default ProtectedRoute;
