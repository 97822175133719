import React, { useContext, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { MenuIcon, OutlineLogoutIcon } from "../icons";
import { Dropdown, DropdownItem } from "@windmill/react-ui";
import { AuthContext } from "../context/AuthContext";
import { FaUserCircle, FaUserEdit } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const { user, signOut } = useContext(AuthContext);
  // console.log(user);
  const { toggleSidebar } = useContext(SidebarContext);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  }

  return (
    <header className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800">
      <div className="container flex items-center justify-between lg:justify-end h-full px-6 mx-auto text-nicegreen dark:text-nicegreen">
        {/* <!-- Mobile hamburger --> */}
        <button
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-nicegreen"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>

        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Profile menu --> */}
          <li className="relative">
            <button
              className="rounded-full focus:shadow-outline-nicegreen focus:outline-none flex items-center"
              onClick={handleProfileClick}
              aria-label="Account"
              aria-haspopup="true"
            >
              <FaUserCircle fill="#484750" size={20} />
              <span className="mx-3 text-gray-700 text-sm font-semibold">
                {(user && `${user.firstName} ${user.lastName}`) ?? "USERNAME"}
              </span>
            </button>

            <Dropdown
              align="right"
              isOpen={isProfileMenuOpen}
              onClose={() => setIsProfileMenuOpen(false)}
            >
              {user?.Type?.name === "individual" && (
                <DropdownItem
                  onClick={() => navigate(`/app/application/edit-user-profile`)}
                >
                  <FaUserEdit className="w-4 h-4 mr-3" aria-hidden="true" />
                  <span>Edit Profile</span>
                </DropdownItem>
              )}
              {user?.Type?.name === "company" && user?.isAdmin && (
                <DropdownItem
                  onClick={() =>
                    navigate(`/app/application/edit-company-profile`)
                  }
                >
                  <FaUserEdit className="w-4 h-4 mr-3" aria-hidden="true" />
                  <span>Edit Company Profile</span>
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() =>
                  navigate(`/app/application/change-password-user`)
                }
              >
                <RiLockPasswordFill
                  className="w-4 h-4 mr-3"
                  aria-hidden="true"
                />
                <span>Change Password</span>
              </DropdownItem>
              <DropdownItem onClick={signOut}>
                <OutlineLogoutIcon
                  className="w-4 h-4 mr-3"
                  aria-hidden="true"
                />
                <span>Log out</span>
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
